@font-face {
  font-family: 'museo_sans_cyrl';
  src: url("../fonts/museosanscyrl_100-webfont.woff2") format("woff2"), url("../fonts/museosanscyrl_100-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'museo_sans_cyrl';
  src: url("../fonts/museosanscyrl_300-webfont.woff2") format("woff2"), url("../fonts/museosanscyrl_300-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'museo_sans_cyrl';
  src: url("../fonts/museosanscyrl_500-webfont.woff2") format("woff2"), url("../fonts/museosanscyrl_500-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'museo_sans_cyrl';
  src: url("../fonts/museosanscyrl_700-webfont.woff2") format("woff2"), url("../fonts/museosanscyrl_700-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'museo_sans_cyrl';
  font-weight: 500; }

.u-link {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-size: 1.05vw;
  font-weight: 500;
  letter-spacing: -0.5px;
  border: none;
  border-bottom: 2px solid transparent;
  line-height: 1.75vw;
  color: #000000;
  text-decoration: none;
  background: transparent;
  padding: 0;
  overflow: hidden; }
  .u-link:before, .u-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    background: #eb5a34;
    height: 2px;
    width: 100%;
    transition: all .6s ease; }
  @media screen and (max-width: 768PX) {
    .u-link {
      font-size: 14px;
      line-height: 22px;
      font-weight: 700;
      letter-spacing: 0; } }
  .u-link:after {
    right: 180%; }
  .u-link:before {
    left: 0; }
  .u-link:hover:before, .u-link:hover:after {
    transform: translateX(180%); }
  .u-link.-white {
    color: #ffffff; }
  .u-link.-big {
    font-size: 1.45161vw; }
    @media screen and (max-width: 768PX) {
      .u-link.-big {
        font-size: 20px;
        line-height: 1.4; } }
    .u-link.-big[disabled] {
      color: rgba(255, 255, 255, 0.4); }
      .u-link.-big[disabled]:before, .u-link.-big[disabled]:after {
        opacity: 0; }

.fl-r {
  float: right; }

::-webkit-input-placeholder {
  font-weight: 100;
  font-size: 1.29032vw;
  color: rgba(255, 255, 255, 0.7); }
  @media screen and (max-width: 768PX) {
    ::-webkit-input-placeholder {
      font-size: 14px; } }

::-moz-placeholder {
  font-weight: 100;
  font-size: 1.29032vw;
  color: rgba(255, 255, 255, 0.7); }
  @media screen and (max-width: 768PX) {
    ::-moz-placeholder {
      font-size: 14px; } }

:-ms-input-placeholder {
  font-weight: 100;
  font-size: 1.29032vw;
  color: rgba(255, 255, 255, 0.7); }
  @media screen and (max-width: 768PX) {
    :-ms-input-placeholder {
      font-size: 14px; } }

:-moz-placeholder {
  font-weight: 100;
  font-size: 1.29032vw;
  color: rgba(255, 255, 255, 0.7); }
  @media screen and (max-width: 768PX) {
    :-moz-placeholder {
      font-size: 14px; } }

.screen-block {
  display: none;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0.4s; }
  @media screen and (max-width: 768PX) {
    .screen-block {
      transition-delay: 0s; } }
  .screen-block .background-wrapper,
  .screen-block .inner-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0; }
  .screen-block .background-wrapper {
    transition: all .6s ease 1s; }
    @media screen and (max-width: 768PX) {
      .screen-block .background-wrapper {
        transition-delay: 0s; } }
  .screen-block .inner-content {
    transition: all .4s ease; }
  .screen-block.isActive {
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0.4s;
    clip: rect(0, 90.32258vw, 39.91935vw, 0); }
    @media screen and (max-width: 768PX) {
      .screen-block.isActive {
        transition-delay: 0s; } }
    @media screen and (min-width: 769PX) {
      .screen-block.isActive.benefits {
        bottom: auto;
        top: 0; }
        .screen-block.isActive.benefits .benefit-item {
          opacity: 1; }
          .screen-block.isActive.benefits .benefit-item:nth-child(1) {
            transition-delay: 1s; }
            .screen-block.isActive.benefits .benefit-item:nth-child(1) .icon-image-container {
              transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;
              transform: scale(1); }
          .screen-block.isActive.benefits .benefit-item:nth-child(2) {
            transition-delay: 1.1s; }
            .screen-block.isActive.benefits .benefit-item:nth-child(2) .icon-image-container {
              transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.1s;
              transform: scale(1); }
          .screen-block.isActive.benefits .benefit-item:nth-child(3) {
            transition-delay: 1.2s; }
            .screen-block.isActive.benefits .benefit-item:nth-child(3) .icon-image-container {
              transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.2s;
              transform: scale(1); }
          .screen-block.isActive.benefits .benefit-item:nth-child(4) {
            transition-delay: 1.3s; }
            .screen-block.isActive.benefits .benefit-item:nth-child(4) .icon-image-container {
              transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.3s;
              transform: scale(1); } }
    @media screen and (max-width: 768PX) {
      .screen-block.isActive.benefits {
        left: 0;
        top: 80px;
        height: auto;
        clip: auto; } }
    .screen-block.isActive .background-wrapper {
      opacity: 1;
      transition: opacity .4s ease; }
    .screen-block.isActive .inner-content {
      opacity: 1;
      transition: opacity .4s ease 1s; }
      @media screen and (max-width: 768PX) {
        .screen-block.isActive .inner-content {
          transition-delay: 0s; } }
  .screen-block.feedback-block .background-wrapper {
    transition-delay: .6s; }
    @media screen and (max-width: 768PX) {
      .screen-block.feedback-block .background-wrapper {
        transition-delay: 0s;
        min-height: 100%; } }
  .screen-block.feedback-block.isActive {
    transition-delay: 0; }
    @media screen and (max-width: 768PX) {
      .screen-block.feedback-block.isActive {
        clip: auto; } }
    .screen-block.feedback-block.isActive .background-wrapper {
      transition-delay: 0s; }
    .screen-block.feedback-block.isActive .inner-content {
      transition-delay: .6s; }
      @media screen and (max-width: 768PX) {
        .screen-block.feedback-block.isActive .inner-content {
          transition-delay: 0s; } }

[data-open] {
  cursor: pointer;
  transition: all .4s ease .6s; }
  [data-open].isFaded {
    transition: all .4s ease .4s; }
    @media screen and (min-width: 769PX) {
      [data-open].isFaded {
        opacity: 0; } }
    @media screen and (max-width: 768PX) {
      [data-open].isFaded.main-block .main-slides {
        opacity: 0; } }

@media screen and (min-width: 769PX) {
  .ms {
    display: none !important; } }

@media screen and (max-width: 768PX) {
  .ds {
    display: none !important; } }

.mobile-adress {
  padding: 60px 20px; }
  .mobile-adress .title {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.44px;
    line-height: 12px; }
    .mobile-adress .title a {
      cursor: pointer;
      margin-left: 12px;
      border-bottom: 1px dashed;
      font-weight: 300; }
      .mobile-adress .title a:first-child {
        margin-left: 0; }
      .mobile-adress .title a.isActive {
        border: none;
        font-weight: 700; }
  .mobile-adress .desc {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 20px; }
  .mobile-adress .navigate {
    margin-top: 40px; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #24459c inset;
  -webkit-text-fill-color: #ffffff !important; }

.relative {
  position: relative; }
  .relative > .absolute {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0; }

body,
html {
  height: 100%; }

body {
  transition: background .6s ease; }
  body.isBlue {
    background: #24459c; }
    body.isBlue .u-gridworks-logo svg path {
      fill: #fff; }
    body.isBlue .header .logo svg .st0 {
      fill: #ffffff; }
    body.isBlue .u-link {
      color: #fff; }
    body.isBlue .footer .credit {
      color: #fff; }

.page-wrapper {
  margin: 0 4.83vw;
  height: 100%; }
  @media screen and (max-width: 768PX) {
    .page-wrapper {
      margin: 0 20px;
      height: 100%; } }

.header {
  position: relative;
  z-index: 1;
  height: 7.74vw;
  line-height: 7.74vw; }
  @media screen and (min-width: 769PX) {
    .header {
      opacity: 0; } }
  @media screen and (max-width: 768PX) {
    .header {
      height: 89px;
      line-height: 89px; } }
  .header .fl-r {
    height: 100%; }
  .header .logo {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 1.42vw;
    width: 6.45vw;
    cursor: pointer; }
    @media screen and (max-width: 768PX) {
      .header .logo {
        width: 80px;
        height: 19px; } }
    .header .logo svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 102%; }
      .header .logo svg .st0 {
        fill: #2C388E; }
  .header .phone {
    vertical-align: middle;
    margin-left: 3.22vw; }
  .header .price {
    vertical-align: middle; }
    @media screen and (max-width: 768PX) {
      .header .price {
        margin-left: 20px; } }
    .header .price .pdf-icon {
      position: relative;
      bottom: 0.24194vw;
      display: inline-block;
      vertical-align: middle;
      width: 1.29032vw;
      height: 1.29032vw;
      background: url(../images/pdf.svg) center/100% no-repeat;
      margin-left: 0.80645vw;
      transition: all .4s ease; }
      @media screen and (max-width: 768PX) {
        .header .price .pdf-icon {
          display: none; } }
    .header .price:hover .pdf-icon {
      transform: scale(1.2);
      backface-visibility: hidden; }

.footer {
  position: relative;
  z-index: 1;
  height: 7.74vw;
  line-height: 7.74vw; }
  @media screen and (min-width: 769PX) {
    .footer {
      opacity: 0; } }
  @media screen and (max-width: 768PX) {
    .footer {
      line-height: 80px;
      height: 80px;
      background: #f8f8f8;
      margin: 0 -20px;
      padding: 0 40px; } }
  .footer .fl-r {
    height: 100%; }
    @media screen and (max-width: 768PX) {
      .footer .fl-r {
        float: none; } }
  .footer .credit {
    font-size: 1.05vw;
    opacity: 0.2; }
    @media screen and (max-width: 768PX) {
      .footer .credit {
        font-size: 14px;
        font-weight: 300; } }
    .footer .credit a {
      text-decoration: none;
      color: inherit; }

.slick-dots {
  position: absolute;
  left: 9.27419vw;
  font-size: 0;
  padding: 0;
  margin: 0; }
  @media screen and (max-width: 768PX) {
    .slick-dots {
      left: 20px; } }
  .slick-dots li {
    position: relative;
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0.64516vw;
    height: 0.64516vw;
    border-radius: 0.64516vw;
    background: #fff;
    opacity: .2;
    margin-left: 1.77419vw;
    padding: 0;
    border: none; }
    @media screen and (max-width: 768PX) {
      .slick-dots li {
        width: 8px;
        height: 8px;
        margin-left: 10px;
        border-radius: 8px; } }
    .slick-dots li button {
      position: absolute;
      width: 1.6129vw;
      height: 1.6129vw;
      top: 50%;
      left: 50%;
      margin-left: -0.80645vw;
      margin-top: -0.80645vw;
      opacity: 0; }
      @media screen and (max-width: 768PX) {
        .slick-dots li button {
          width: 20px;
          height: 20px;
          margin-left: -10px;
          margin-top: -10px; } }
    .slick-dots li.slick-active {
      opacity: 1; }
    .slick-dots li:first-child {
      margin-left: 0; }

.main-screen {
  padding: 7.66vw 0;
  box-sizing: border-box;
  margin: -7.8vw 0;
  min-height: 100%; }
  @media screen and (min-width: 769PX) {
    .main-screen {
      -webkit-perspective: 800PX;
      perspective: 800PX; } }
  @media screen and (max-width: 768PX) {
    .main-screen {
      padding: 0;
      margin: 0;
      min-height: auto; } }
  .main-screen .size-canvas {
    display: block;
    width: 100%; }
  .main-screen > .content {
    position: relative; }
    @media screen and (min-width: 769PX) {
      .main-screen > .content {
        transform-style: preserve-3d;
        transform-origin: 50% 150%;
        transform: rotateX(15deg);
        backface-visibility: hidden;
        opacity: 0; } }
    @media screen and (max-width: 768PX) {
      .main-screen > .content {
        overflow: hidden; } }
    .main-screen > .content .main-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 77.68%;
      height: 100%;
      background: #24459c;
      overflow: hidden;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      cursor: default; }
      @media screen and (max-width: 768PX) {
        .main-screen > .content .main-block {
          position: relative;
          height: auto;
          width: 100%;
          border-radius: 6px; } }
      .main-screen > .content .main-block .slick-dots {
        bottom: 2.5vw; }
        @media screen and (max-width: 768PX) {
          .main-screen > .content .main-block .slick-dots {
            bottom: 108px; } }
    .main-screen > .content .extra {
      position: absolute;
      right: 0;
      width: 21.42%;
      height: 48.98%; }
      .main-screen > .content .extra.-product-preview {
        top: 0;
        border-top-right-radius: 6px;
        background: #f8f8f8; }
        .main-screen > .content .extra.-product-preview .image {
          width: 12.09677vw;
          height: 12.09677vw;
          margin-bottom: 1.37097vw;
          background-position: left bottom;
          background-size: contain;
          background-repeat: no-repeat;
          transition: all .4s ease; }
        .main-screen > .content .extra.-product-preview:hover .image {
          transform: scale(1.1); }
      .main-screen > .content .extra.-contacts {
        overflow: hidden;
        bottom: 0;
        border-bottom-right-radius: 6px;
        background: #24459c;
        color: #fff; }
        .main-screen > .content .extra.-contacts:before {
          content: "";
          position: absolute;
          bottom: -9.27419vw;
          right: -9.27419vw;
          width: 18.54839vw;
          height: 18.54839vw;
          border-radius: 18.54839vw;
          background: #eb5a34;
          transition: all .4s ease; }
        .main-screen > .content .extra.-contacts:hover:before {
          transform: scale(1.2); }

.benefits {
  display: none;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 769PX) {
    .benefits {
      clip: rect(0, 90.32258vw, 19.35484vw, 70.96774vw);
      transform: translate(-20.16129vw, 0); } }
  @media screen and (max-width: 768PX) {
    .benefits {
      top: 80px;
      bottom: 0;
      left: 0;
      height: auto;
      transform: translate(0, 100%);
      overflow: visible; } }
  .benefits .background-wrapper {
    background: #eb5a34;
    border-radius: 6px;
    min-height: 100%; }
  @media screen and (max-width: 768PX) {
    .benefits .title.u-big-title {
      font-weight: 700;
      font-size: 14px;
      margin-top: 31px; } }
  @media screen and (max-width: 768PX) {
    .benefits .benefits-close {
      top: -53px; } }
  .benefits .next-link {
    color: #fff;
    bottom: 24px; }
    .benefits .next-link:after {
      background: url(../images/icons/arrow-right-white.svg) center right/contain no-repeat; }

.benefits-preview {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50.80645vw;
  right: 0;
  bottom: 20.16129vw;
  padding: 0 3.06452vw;
  box-sizing: border-box;
  overflow: hidden;
  text-align: right;
  color: #ffffff;
  background: #eb5a34;
  border-bottom-left-radius: 6px;
  transition: none; }
  @media screen and (min-width: 769PX) {
    .benefits-preview {
      transform: translateX(100%); } }
  @media screen and (max-width: 768PX) {
    .benefits-preview {
      border-bottom-right-radius: 6px;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80px;
      padding: 0 20px;
      text-align: left;
      line-height: 80px; } }
  .benefits-preview .title {
    position: relative;
    font-size: 1.93548vw;
    font-weight: bold;
    margin-top: 5.96774vw; }
  .benefits-preview .desc {
    margin-top: 3.54839vw;
    font-size: 1.29032vw;
    font-weight: bold; }
    @media screen and (max-width: 768PX) {
      .benefits-preview .desc {
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: -0.17px;
        margin: 0; }
        .benefits-preview .desc span {
          display: inline-block;
          line-height: normal;
          border-bottom: 1px dashed; } }

.benefits-dash {
  position: absolute;
  z-index: 5;
  top: 6.85484vw;
  left: 53.22581vw;
  width: 2.82258vw;
  height: 0.32258vw;
  background: #ffffff;
  opacity: 0; }

.u-big-title {
  position: relative;
  font-size: 2.90323vw;
  font-weight: 500;
  line-height: 1.17;
  color: #ffffff;
  margin-top: 4.83871vw; }
  .u-big-title:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 1.6129vw;
    margin-right: 1.6129vw;
    display: inline-block;
    vertical-align: middle;
    width: 2.82258vw;
    height: 0.32258vw;
    background: #ffffff; }
    @media screen and (max-width: 768PX) {
      .u-big-title:before {
        display: none; } }
  .u-big-title.-black {
    color: #000000; }
    .u-big-title.-black:before {
      background: #000; }
  .u-big-title.-no-dash:before {
    display: none; }
  .u-big-title.-light {
    font-weight: 300; }
    .u-big-title.-light:before {
      height: 0.16129vw;
      top: 1.69355vw; }

.main-slides {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  @media screen and (max-width: 768PX) {
    .main-slides {
      position: relative;
      padding-bottom: 150px;
      transition: opacity .4s ease; } }
  .main-slides .slide {
    outline: none; }
    @media screen and (min-width: 769PX) {
      .main-slides .slide .main-wrapper {
        padding-right: 19.35484vw; } }
    @media screen and (max-width: 768PX) {
      .main-slides .slide .title {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: -0.21px;
        line-height: 30px;
        margin-top: 40px; } }
    .main-slides .slide .icon {
      margin-top: 1.45161vw; }
      @media screen and (max-width: 768PX) {
        .main-slides .slide .icon {
          margin-top: 20px; } }
      .main-slides .slide .icon .icon-wrapper {
        position: relative;
        display: inline-block;
        cursor: pointer; }
        .main-slides .slide .icon .icon-wrapper.isFaded {
          transform: scale(0) !important; }
        .main-slides .slide .icon .icon-wrapper:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -2.09677vw;
          margin-top: -2.09677vw;
          border-radius: 4.19355vw;
          width: 4.19355vw;
          height: 4.19355vw;
          background-color: #24459c;
          background-image: url(../images/icons/arrow-slider-right.svg);
          background-position: center;
          background-size: 50%;
          background-repeat: no-repeat;
          transform: scale(0);
          transition: all .4s ease; }
        .main-slides .slide .icon .icon-wrapper:hover:before {
          transform: scale(1); }
      .main-slides .slide .icon img {
        width: 4.83871vw; }
        @media screen and (max-width: 768PX) {
          .main-slides .slide .icon img {
            width: 50px; } }
    .main-slides .slide .desc {
      margin-top: 5.16129vw;
      font-size: 1.12903vw;
      font-weight: 300;
      line-height: 1.5;
      color: #ffffff; }
      @media screen and (max-width: 768PX) {
        .main-slides .slide .desc {
          font-weight: 300;
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 24px;
          margin-top: 37px; } }
    @media screen and (min-width: 769PX) {
      .main-slides .slide .title,
      .main-slides .slide .desc {
        transition: all .4s ease-out; }
      .main-slides .slide .icon .icon-wrapper {
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform: scale(0); }
      .main-slides .slide.slick-active .title,
      .main-slides .slide.slick-active .desc {
        opacity: 1; }
      .main-slides .slide.slick-active .title {
        transition-delay: .2s; }
      .main-slides .slide.slick-active .icon .icon-wrapper {
        transform: scale(1);
        transition-delay: .4s; }
      .main-slides .slide.slick-active .desc {
        transition-delay: .6s; } }

.main-wrapper {
  padding: 0 9.27419vw; }
  @media screen and (max-width: 768PX) {
    .main-wrapper {
      padding: 0 20px; } }

.benefits-list {
  margin-top: 8.06452vw;
  padding: 0;
  list-style: none; }
  @media screen and (min-width: 769PX) {
    .benefits-list {
      width: 105%; } }
  @media screen and (max-width: 768PX) {
    .benefits-list {
      margin: 0;
      position: absolute;
      top: 84px;
      left: 0;
      right: 0;
      bottom: 0; }
      .benefits-list .slick-dots {
        bottom: 28px; } }

@keyframes wallet {
  0% {
    transform: rotate(-360deg) scale(0); }
  100% {
    transform: rotate(0) scale(1); } }

@keyframes handshake {
  0% {
    transform: translateY(0); }
  33% {
    transform: translateY(-2%); }
  66% {
    transform: translateY(4%); }
  100% {
    transform: translateY(0); } }

@keyframes rocket {
  0% {
    transform: translate(0, 0); }
  16.6% {
    transform: translate(-20%, 20%); }
  33.3% {
    transform: translate(-20%, 0); }
  50% {
    transform: translate(0, 20%); }
  66.6% {
    transform: translate(-20%, 20%); }
  83.3% {
    transform: translate(0, 20%); }
  100% {
    transform: translate(0, 0); } }

@keyframes mark {
  0% {
    transform: rotate(0); }
  30% {
    transform: rotate(-20deg); }
  60% {
    transform: rotate(20deg); }
  90% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(0); } }

.benefit-item {
  float: left;
  width: 16.12903vw;
  margin-right: 2.41935vw;
  color: #ffffff;
  box-sizing: border-box; }
  @media screen and (min-width: 769PX) {
    .benefit-item {
      opacity: 0;
      transition: opacity .4s ease; } }
  @media screen and (max-width: 768PX) {
    .benefit-item {
      width: auto;
      margin: 0;
      padding: 0 20px; } }
  .benefit-item.-longone {
    width: 16.93548vw; }
  .benefit-item:last-child {
    margin-right: 0; }
  .benefit-item > .icon {
    font-size: 0; }
    .benefit-item > .icon .image {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 4.83871vw;
      width: 4.83871vw;
      border-radius: 4.83871vw;
      background-color: #24459c;
      transition: all .4s ease; }
      @media screen and (max-width: 768PX) {
        .benefit-item > .icon .image {
          width: 50px;
          height: 50px;
          border-radius: 50px; } }
  .benefit-item > .title {
    margin-top: 2.17742vw;
    height: 3.87097vw;
    font-size: 1.45161vw;
    font-weight: 500;
    line-height: 1.33; }
    @media screen and (max-width: 768PX) {
      .benefit-item > .title {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.21px;
        line-height: 30px;
        margin-top: 15px;
        height: auto; } }
  .benefit-item > .desc {
    margin-top: 1.04839vw;
    font-size: 0.96774vw;
    font-weight: 300;
    line-height: 1.333; }
    @media screen and (max-width: 768PX) {
      .benefit-item > .desc {
        margin-top: 24px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px; } }
  .benefit-item .icon-image-container {
    position: relative;
    display: inline-block; }
    @media screen and (min-width: 769PX) {
      .benefit-item .icon-image-container {
        transform: scale(0); } }
  @media screen and (min-width: 769PX) {
    .benefit-item:hover > .icon .image, .benefit-item.slick-active > .icon .image {
      transform: scale(1.2) !important;
      transition-delay: 0s !important; } }
  .benefit-item:hover > .icon .image .benefit-icon.-n1, .benefit-item.slick-active > .icon .image .benefit-icon.-n1 {
    animation: wallet .5s 1; }
  .benefit-item:hover > .icon .image .benefit-icon.-n2, .benefit-item.slick-active > .icon .image .benefit-icon.-n2 {
    animation: handshake .5s 2; }
  .benefit-item:hover > .icon .image .benefit-icon.-n3:before, .benefit-item.slick-active > .icon .image .benefit-icon.-n3:before {
    animation: rocket .2s 5; }
  .benefit-item:hover > .icon .image .benefit-icon.-n4, .benefit-item.slick-active > .icon .image .benefit-icon.-n4 {
    transform-origin: 50% 45%;
    animation: mark .5s 1; }
  @media screen and (max-width: 768PX) {
    .benefit-item:hover > .icon .image .benefit-icon, .benefit-item.slick-active > .icon .image .benefit-icon {
      animation-delay: .5s !important; } }

.benefits-close {
  position: absolute;
  z-index: 1;
  display: inline-block;
  top: 5.08065vw;
  right: 4.43548vw;
  width: 2.58065vw;
  height: 2.58065vw;
  background: url(../images/cross.svg) center/100%;
  transition: all .4s ease; }
  @media screen and (max-width: 768PX) {
    .benefits-close {
      width: 32px;
      height: 32px; } }
  .benefits-close.-black {
    background: url(../images/cross-black.svg) center/100%; }
  @media screen and (min-width: 769PX) {
    .benefits-close:hover {
      transform: rotate(180deg);
      backface-visibility: hidden; } }

.extra-content {
  position: absolute;
  bottom: 1.69355vw;
  left: 2.41935vw;
  right: 2.41935vw; }
  .extra-content.-with-dash:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 12.5vw;
    display: inline-block;
    vertical-align: middle;
    width: 2.82258vw;
    height: 0.32258vw;
    background: #ffffff; }
  .extra-content > .title {
    font-weight: 700;
    font-size: 1.29032vw;
    letter-spacing: 0; }
  .extra-content > .desc {
    font-weight: 300;
    font-size: 1.12903vw;
    letter-spacing: 0;
    line-height: 1.69355vw;
    margin-top: 0.32258vw; }

.catalog {
  display: none;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  clip: rect(0, 90.32258vw, 19.35484vw, 70.96774vw);
  transform: translate(0, 0); }
  .catalog .background-wrapper {
    background: #f8f8f8;
    border-radius: 6px; }

.catalog-list {
  list-style: none;
  margin: 2.01613vw -2.01613vw 0;
  padding: 0;
  background: #f8f8f8; }
  @media screen and (min-width: 769PX) {
    .catalog-list {
      position: relative;
      white-space: nowrap;
      width: 115.5%;
      box-sizing: border-box; }
      .catalog-list:after {
        content: "";
        position: absolute;
        z-index: 2;
        pointer-events: none;
        right: 0;
        top: 0;
        bottom: 0;
        width: 16.12903vw;
        background: linear-gradient(to right, rgba(248, 248, 248, 0) 0%, #f8f8f8 100%); }
      .catalog-list .catalog-list__inner:after {
        content: "";
        display: inline-block;
        width: 16.12903vw;
        height: 1px; }
      .catalog-list .mCSB_scrollTools.mCSB_scrollTools_horizontal {
        z-index: 3;
        right: 3.22581vw; }
      .catalog-list .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
        width: 20.16129vw !important; }
      .catalog-list .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        height: 5px;
        margin: 5px auto;
        border-radius: 5px;
        background: #24459c; }
      .catalog-list .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        height: 5px;
        margin: 5px auto;
        background: #fff;
        border-radius: 5px; } }
  @media screen and (max-width: 768PX) {
    .catalog-list {
      margin: 0; } }

.catalog-item {
  position: relative;
  width: 9.67742vw;
  padding: 2.01613vw 2.01613vw 0;
  display: inline-block;
  vertical-align: top;
  font-size: 1.29032vw;
  border-radius: 6px;
  transition: all .4s ease;
  white-space: normal;
  box-sizing: border-box; }
  @media screen and (min-width: 769PX) {
    .catalog-item:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      opacity: 0;
      transition: all .4s ease; }
    .catalog-item.-hover1:before {
      background-image: url(../images/animation/3.svg);
      background-position: left 20% center;
      background-size: 200%;
      background-repeat: no-repeat; }
    .catalog-item.-hover2:before {
      background-image: url(../images/animation/4.svg);
      background-position: left 20% center;
      background-size: 200%;
      background-repeat: no-repeat; }
    .catalog-item.-hover3:before {
      background-image: url(../images/animation/2.svg);
      background-position: left 20% center;
      background-size: 200%;
      background-repeat: no-repeat; } }
  @media screen and (max-width: 768PX) {
    .catalog-item {
      width: auto;
      padding: 26px 20px 85px;
      font-size: 16px;
      outline: none; } }
  .catalog-item .section, .catalog-item .image, .catalog-item .name, .catalog-item .desc, .catalog-item .price, .catalog-item .link {
    position: relative;
    z-index: 2; }
  .catalog-item .section {
    font-weight: 300;
    font-size: 0.80645vw;
    letter-spacing: 0;
    line-height: 1.12903vw;
    min-height: 2.25806vw; }
    @media screen and (min-width: 769PX) {
      .catalog-item .section {
        width: 115%; } }
    @media screen and (max-width: 768PX) {
      .catalog-item .section {
        font-weight: 300;
        min-height: 0;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
        height: 42px; } }
  .catalog-item .image {
    width: 8.06452vw;
    height: 8.06452vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    margin-top: 0.56452vw; }
    @media screen and (max-width: 768PX) {
      .catalog-item .image {
        position: absolute;
        bottom: 90px;
        width: 33%;
        height: 144px;
        right: 5px;
        margin-top: 0;
        background-position: right bottom; } }
  .catalog-item .name {
    margin-top: 1.6129vw;
    font-weight: 700;
    font-size: 1.29032vw;
    letter-spacing: 0; }
    @media screen and (max-width: 768PX) {
      .catalog-item .name {
        font-size: 20px;
        letter-spacing: 0;
        margin-top: 20px;
        height: 23px; } }
  .catalog-item .desc {
    font-weight: 300;
    font-size: 0.96774vw;
    letter-spacing: 0;
    line-height: 1.69355vw; }
    @media screen and (max-width: 768PX) {
      .catalog-item .desc {
        font-size: 14px;
        line-height: 21px;
        height: 21px; } }
  .catalog-item .price {
    margin-top: 1.29032vw;
    font-weight: 300;
    font-size: 1.93548vw;
    letter-spacing: -0.05403vw; }
    @media screen and (max-width: 768PX) {
      .catalog-item .price {
        font-weight: 700;
        font-size: 36px;
        letter-spacing: -1px;
        margin-top: 24px; } }
  .catalog-item .link {
    display: block;
    margin: 0.8871vw -2.01613vw 0;
    padding: 0 2.01613vw;
    opacity: 0;
    transition: all .4s ease;
    height: 4.03226vw;
    line-height: 4.03226vw;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; }
    .catalog-item .link span {
      transition: all .4s ease;
      color: #fff;
      vertical-align: middle; }
    .catalog-item .link:hover {
      background: #eb5a34; }
  @media screen and (min-width: 769PX) {
    .catalog-item:hover {
      color: #fff;
      background: #24459c; }
      .catalog-item:hover .link {
        opacity: 1; }
        .catalog-item:hover .link a {
          color: #fff; }
      .catalog-item:hover:before {
        opacity: .2; } }

.contacts {
  display: none;
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  clip: rect(20.16129vw, 90.32258vw, 39.51613vw, 70.96774vw);
  transform: translate(0, 0); }
  .contacts .background-wrapper {
    background: #24459c;
    border-radius: 6px; }
  .contacts .contacts-map {
    position: absolute;
    left: 0;
    right: 50%;
    top: 0;
    bottom: 0; }
  .contacts .contacts-content {
    position: absolute;
    right: 0;
    left: 50%;
    bottom: 0;
    top: 0;
    padding: 0 5.64516vw; }
  .contacts .contacts-link {
    position: absolute;
    bottom: 4.83871vw;
    right: 4.35484vw;
    bottom: 3.70968vw; }

.contacts-block {
  float: left;
  margin-top: 3.54839vw;
  color: #fff; }
  .contacts-block.-long {
    position: absolute;
    bottom: 3.70968vw; }
  .contacts-block .block-title {
    font-weight: 500;
    font-size: 1.45161vw;
    letter-spacing: 0.02661vw;
    line-height: 1.93548vw; }
    .contacts-block .block-title a {
      display: inline-block;
      position: relative;
      margin-right: 1.85484vw;
      text-decoration: none;
      color: #fff;
      cursor: pointer;
      font-weight: 300; }
      .contacts-block .block-title a:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: url(../images/dotted.png) left/auto 100%; }
      .contacts-block .block-title a.isActive {
        font-weight: 700; }
        .contacts-block .block-title a.isActive:before {
          display: none; }
  .contacts-block .block-content {
    font-weight: 300;
    font-size: 0.96774vw;
    letter-spacing: 0;
    line-height: 1.45161vw;
    margin-top: 2.90323vw; }
    .contacts-block .block-content .city-content {
      display: none; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

.feedback-block {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px; }
  @media screen and (max-width: 768PX) {
    .feedback-block {
      position: fixed;
      z-index: 102;
      clip: auto;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      border-radius: 0; }
      .feedback-block .header {
        padding: 0 20px; }
        .feedback-block .header .logo svg .st0 {
          fill: #ffffff; }
        .feedback-block .header .u-link {
          color: #fff; }
      .feedback-block .benefits-close {
        top: 112px; }
      .feedback-block .main-wrapper > .title {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: -0.21px;
        line-height: 30px;
        margin-top: 24px; }
      .feedback-block .inner-content {
        padding-bottom: 24px; }
      .feedback-block .background-wrapper,
      .feedback-block .inner-content {
        position: relative; } }
  .feedback-block .background-wrapper {
    background: #24459c; }

.u-input {
  font-weight: 100;
  font-size: 1.29032vw;
  border-radius: 0; }
  @media screen and (min-width: 769PX) {
    .u-input {
      margin-bottom: 0.5vw; } }
  @media screen and (max-width: 768PX) {
    .u-input {
      font-size: 14px; } }

.feedback-form {
  width: 56.45161vw;
  margin-top: 4.43548vw;
  color: #FFFFFF; }
  @media screen and (max-width: 768PX) {
    .feedback-form {
      margin-top: 56px;
      width: auto; } }
  .feedback-form .form-inputs {
    overflow: hidden; }
    .feedback-form .form-inputs input {
      background: transparent;
      border: none;
      width: 30%;
      box-sizing: border-box;
      float: left;
      margin-left: 5%;
      height: 2.17742vw;
      outline: none; }
      @media screen and (max-width: 768PX) {
        .feedback-form .form-inputs input {
          width: 100%;
          margin-left: 0;
          height: 34px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          margin-top: 16px;
          transition: border .2s ease; }
          .feedback-form .form-inputs input:focus {
            outline: none;
            border-bottom: 1px solid white; } }
      .feedback-form .form-inputs input:first-child {
        margin-left: 0; }
        @media screen and (max-width: 768PX) {
          .feedback-form .form-inputs input:first-child {
            margin-top: 0; } }
  .feedback-form .form-textarea {
    margin-top: 2.41935vw; }
    .feedback-form .form-textarea textarea {
      width: 100%;
      height: 11.69355vw;
      background: transparent;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      resize: none;
      outline: none;
      line-height: 1.93548vw; }
      @media screen and (max-width: 768PX) {
        .feedback-form .form-textarea textarea {
          height: 200px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          margin-top: 16px;
          transition: border .2s ease;
          line-height: 24px; }
          .feedback-form .form-textarea textarea:focus {
            border-bottom: 1px solid white;
            outline: none; } }
    .feedback-form .form-textarea .textarea-left {
      font-weight: 300;
      font-size: 0.72581vw;
      letter-spacing: 0;
      color: rgba(255, 255, 255, 0.4); }
  .feedback-form .form-submit {
    margin-top: 2.41935vw; }
    @media screen and (max-width: 768PX) {
      .feedback-form .form-submit {
        margin-top: 24px; } }

.animated-icons {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none; }
  @media screen and (max-width: 768PX) {
    .animated-icons {
      bottom: 80px; } }
  .animated-icons svg {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: opacity .6s ease; }
    .animated-icons svg.isActive {
      opacity: 1; }
    .animated-icons svg.-n1 {
      width: 80%; }
    .animated-icons svg.-n2 {
      width: 90%;
      bottom: -2vw;
      right: -42%; }
      @media screen and (max-width: 768PX) {
        .animated-icons svg.-n2 {
          width: 110%;
          bottom: -6vw;
          right: -60%; } }
    .animated-icons svg.-n3 {
      width: 34%;
      bottom: 2.82258vw;
      right: 4.83871vw; }
    .animated-icons svg.-n4 {
      width: 55%;
      bottom: -6.45161vw; }

.mobile-catalog {
  position: relative;
  margin-top: 20px; }
  .mobile-catalog .slick-dots {
    bottom: 36px; }
    .mobile-catalog .slick-dots li {
      background: rgba(36, 69, 156, 0.2);
      opacity: 1; }
      .mobile-catalog .slick-dots li.slick-active {
        background: #24459c; }
  .mobile-catalog .next-link {
    color: #24459C;
    bottom: 33px; }

.next-link {
  position: absolute;
  z-index: 1;
  font-weight: 700;
  font-size: 14px;
  right: 20px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.12px;
  text-decoration: none;
  text-transform: lowercase; }
  .next-link:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 7px;
    height: 10px;
    margin-left: 9px;
    background: url(../images/icons/arrow-right-blue.svg) center right/contain no-repeat; }

.mobile-order {
  display: block;
  height: 80px;
  line-height: 80px;
  padding: 0 20px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #24459c;
  color: #fff; }
  .mobile-order span {
    color: #fff;
    vertical-align: middle; }

.benefit-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .benefit-icon.-n1 {
    background: url(../images/icons/about-1.svg) center/48% no-repeat; }
  .benefit-icon.-n2 {
    background: url(../images/icons/about-2.svg) center/57% no-repeat; }
  .benefit-icon.-n3 {
    background: url(../images/icons/about-3-1.svg) center/45% no-repeat; }
    .benefit-icon.-n3:before {
      content: "";
      position: absolute;
      top: 57%;
      right: 57%;
      width: 13.5%;
      height: 13.5%;
      background: url(../images/icons/about-3-2.svg) center/100% no-repeat; }
  .benefit-icon.-n4 {
    background: url(../images/icons/about-4.svg) center/35% no-repeat; }

.u-gridworks-logo {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  top: 0.40323vw;
  width: 3.22581vw;
  height: 1.53226vw;
  margin-left: 0.56452vw; }
  @media screen and (max-width: 768PX) {
    .u-gridworks-logo {
      width: 40px;
      height: 19px;
      margin-left: 7px;
      top: 5px; } }
  .u-gridworks-logo svg {
    width: 102%;
    position: absolute;
    top: 0;
    left: 0; }

.feedback-sent {
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 30px; }
  @media screen and (max-width: 768PX) {
    .feedback-sent {
      font-size: 14px; } }
