@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('../fonts/museosanscyrl_100-webfont.woff2') format('woff2'),
         url('../fonts/museosanscyrl_100-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('../fonts/museosanscyrl_300-webfont.woff2') format('woff2'),
         url('../fonts/museosanscyrl_300-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('../fonts/museosanscyrl_500-webfont.woff2') format('woff2'),
         url('../fonts/museosanscyrl_500-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans_cyrl';
    src: url('../fonts/museosanscyrl_700-webfont.woff2') format('woff2'),
         url('../fonts/museosanscyrl_700-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}