$orange: #eb5a34;
$blue: #24459c;
$gray: #f8f8f8;
$ds: 'screen and (min-width: 769PX)';
$mb: 'screen and (max-width: 768PX)';

@function vwpx($px){
  @return ($px/(1240/100))+vw;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'museo_sans_cyrl';
  font-weight: 500;
}

.u-link {
  position: relative;
	display: inline-block;
	box-sizing: border-box;
	font-size: 1.05vw;
  font-weight: 500;
  letter-spacing: -0.5px;
  border: none;
  border-bottom: 2px solid transparent;
  line-height: 1.75vw;
  color: #000000;
  text-decoration: none;
  background: transparent;
  padding: 0;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    background: $orange;
    height: 2px;
    width: 100%;
    transition: all .6s ease;
  }

  @media #{$mb} {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 0;
  }

  &:after {
    right: 180%;
  }

  &:before {
    left: 0;
  }

  &:hover {
    &:before,
    &:after {
      transform: translateX(180%);
    }
  }

  &.-white {
    color: #ffffff;
  }

  &.-big {
    font-size: vwpx(18);

    @media #{$mb} {
      font-size: 20px;
      line-height: 1.4;
    }

    &[disabled] {
      color: rgba(255, 255, 255, .4);

      &:before,
      &:after {
        opacity: 0;
      }
    }
  }
}

.fl-r {
	float: right;
}

::-webkit-input-placeholder {
  font-weight: 100;
  font-size: vwpx(16);
  color: rgba(255, 255, 255, .7);

  @media #{$mb} {
    font-size: 14px;
  }
}

::-moz-placeholder {
  font-weight: 100;
  font-size: vwpx(16);
  color: rgba(255, 255, 255, .7);

  @media #{$mb} {
    font-size: 14px;
  }
}

:-ms-input-placeholder {
  font-weight: 100;
  font-size: vwpx(16);
  color: rgba(255, 255, 255, .7);

  @media #{$mb} {
    font-size: 14px;
  }
}

:-moz-placeholder {
  font-weight: 100;
  font-size: vwpx(16);
  color: rgba(255, 255, 255, .7);

  @media #{$mb} {
    font-size: 14px;
  }
}

.screen-block {
  display: none;
  overflow: hidden;
  transition: all .6s cubic-bezier(0.77, 0, 0.175, 1) .4s;

  @media #{$mb} {
    transition-delay: 0s;
  }

  .background-wrapper,
  .inner-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  .background-wrapper {
    transition: all .6s ease 1s;

    @media #{$mb} {
      transition-delay: 0s;
    }
  }

  .inner-content {
    transition: all .4s ease;
  }

  &.isActive {
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    transform: translate3d(0, 0, 0);
    transition: all .6s cubic-bezier(0.77, 0, 0.175, 1) .4s;
    clip: rect(0, vwpx(1120), vwpx(495), 0);

    @media #{$mb} {
      transition-delay: 0s;
    }

    &.benefits {
      @media #{$ds} {
        bottom: auto;
        top: 0;

        .benefit-item {
          opacity: 1;

          &:nth-child(1) {
            transition-delay: 1s;

            .icon-image-container {
              transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;
              transform: scale(1);
            }
          }

          &:nth-child(2) {
            transition-delay: 1.1s;

            .icon-image-container {
              transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.1s;
              transform: scale(1);
            }
          }

          &:nth-child(3) {
            transition-delay: 1.2s;

            .icon-image-container {
              transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.2s;
              transform: scale(1);
            }
          }

          &:nth-child(4) {
            transition-delay: 1.3s;

            .icon-image-container {
              transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.3s;
              transform: scale(1);             
            }
          }
        }
      }

      @media #{$mb} {
        left: 0;
        top: 80px;
        height: auto;
        clip: auto;
      }
    }

    .background-wrapper {
      opacity: 1;
      transition: opacity .4s ease;
    }

    .inner-content {
      opacity: 1;
      transition: opacity .4s ease 1s;

      @media #{$mb} {
        transition-delay: 0s;
      }
    }
  }

  &.feedback-block {
    .background-wrapper {
      transition-delay: .6s;

      @media #{$mb} {
        transition-delay: 0s;
        min-height: 100%;
      }
    }

    &.isActive {
      transition-delay: 0;

      @media #{$mb} {
        clip: auto;
      }

      .background-wrapper {
        transition-delay: 0s;
      }

      .inner-content {
        transition-delay: .6s;

        @media #{$mb} {
          transition-delay: 0s;       
        }
      }
    }
  }
}

[data-open] {
  cursor: pointer;
  transition: all .4s ease .6s;

  &.isFaded {
    transition: all .4s ease .4s;

    @media #{$ds} {
      opacity: 0;
    }

    &.main-block {
      @media #{$mb} {
        .main-slides {
          opacity: 0;
        }
      }
    }
  }
}

.ms {
  @media #{$ds} {
    display: none !important;
  }
}

.ds {
  @media #{$mb} {
    display: none !important;
  }
}

.mobile-adress {
  padding: 60px 20px;

  .title {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.44px;
    line-height: 12px;

    a {
      cursor: pointer;
      margin-left: 12px;
      border-bottom: 1px dashed;
      font-weight: 300;

      &:first-child {
        margin-left: 0;
      }

      &.isActive {
        border: none;
        font-weight: 700;
      }
    }
  }

  .desc {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 20px;
  }

  .navigate {
    margin-top: 40px;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px $blue inset;
  -webkit-text-fill-color: #ffffff !important;
}

.relative {
  position: relative;

  > .absolute {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}