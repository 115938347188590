body,
html {
	height: 100%;
}

body {
	transition: background .6s ease;

	&.isBlue {
		background: $blue;

		.u-gridworks-logo {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.header .logo {
			svg {
				.st0 {
					fill: #ffffff;
				}
			}
		}

		.u-link {
			color: #fff;
		}

		.footer {
			.credit {
				color: #fff;
			}
		}
	}
}

.page-wrapper {
	margin: 0 4.83vw;
	height: 100%;

	@media #{$mb} {
		margin: 0 20px;
		height: 100%;
	}
}

.header {
	position: relative;
	z-index: 1;
	height: 7.74vw;
	line-height: 7.74vw;

	@media #{$ds} {
		opacity: 0;
	}

	@media #{$mb} {
		height: 89px;
		line-height: 89px;
	}

	.fl-r {
		height: 100%;
	}

	.logo {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		height: 1.42vw;
		width: 6.45vw;
		cursor: pointer;

		@media #{$mb} {
			width: 80px;
			height: 19px;
		}

		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 102%;

			.st0 {
				fill: #2C388E;
			}
		}
	}

	.phone {
		vertical-align: middle;
		margin-left: 3.22vw;
	}

	.price {
		vertical-align: middle;

		@media #{$mb} {
			margin-left: 20px;
		}

		.pdf-icon {
			position: relative;
			bottom: vwpx(3);
			display: inline-block;
			vertical-align: middle;
			width: vwpx(16);
			height: vwpx(16);
			background: url(../images/pdf.svg) center / 100% no-repeat;
			margin-left: vwpx(10);
			transition: all .4s ease;

			@media #{$mb} {
				display: none;
			}
		}

		&:hover {
			.pdf-icon {
				transform: scale(1.2);
				backface-visibility: hidden;
			}
		}
	}
}

.footer {
	position: relative;
	z-index: 1;
	height: 7.74vw;
	line-height: 7.74vw;
	
	@media #{$ds} {
		opacity: 0;
	}

	@media #{$mb} {
		line-height: 80px;
		height: 80px;
		background: $gray;
		margin: 0 -20px;
		padding: 0 40px;
	}

	.fl-r {
		height: 100%;

		@media #{$mb} {
			float: none;
		}
	}

	.credit {
		font-size: 1.05vw;
		opacity: 0.2;

		@media #{$mb} {
			font-size: 14px;
			font-weight: 300;
		}

		a {
			text-decoration: none;
			color: inherit;
		}
	}
}

.slick-dots {
	position: absolute;
	left: vwpx(115);
	font-size: 0;
	padding: 0;
	margin: 0;

	@media #{$mb} {
		left: 20px;
	}

	li {
		position: relative;
		font-size: 0;
		display: inline-block;
		vertical-align: middle;
		width: vwpx(8);
		height: vwpx(8);
		border-radius: vwpx(8);
		background: #fff;
		opacity: .2;
		margin-left: vwpx(22);
		padding: 0;
		border: none;

		@media #{$mb} {
			width: 8px;
			height: 8px;
			margin-left: 10px;
			border-radius: 8px;
		}

		button {
			position: absolute;
			width: vwpx(20);
			height: vwpx(20);
			top: 50%;
			left: 50%;
			margin-left: vwpx(-10);
			margin-top: vwpx(-10);
			opacity: 0;

			@media #{$mb} {
				width: 20px;
				height: 20px;
				margin-left: -10px;
				margin-top: -10px;
			}
		}

		&.slick-active {
			opacity: 1;
		}

		&:first-child {
			margin-left: 0;
		}
	}
}

.main-screen {
	padding: 7.66vw 0;
	box-sizing: border-box;
	margin: -7.8vw 0;
	min-height: 100%;

	@media #{$ds} {
		-webkit-perspective: 800PX;
		perspective: 800PX;
	}

	@media #{$mb} {
		padding: 0;
		margin: 0;
		min-height: auto;
	}

	.size-canvas {
		display: block;
		width: 100%;
	}

	> .content {
		position: relative;

		@media #{$ds} {
			transform-style: preserve-3d;
			transform-origin: 50% 150%;
			transform: rotateX(15deg);
			backface-visibility: hidden;
			opacity: 0;
		}

		@media #{$mb} {
			overflow: hidden;
		}
	
		.main-block {
			position: absolute;
			top: 0;
			left: 0;
			width: 77.68%;
			height: 100%;
			background: $blue;
			overflow: hidden;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			cursor: default;

			@media #{$mb} {
				position: relative;
				height: auto;
				width: 100%;
				border-radius: 6px;
			}

			.slick-dots {
				bottom: vwpx(31);

				@media #{$mb} {
					bottom: 108px;
				}
			}
		}

		.extra {
			position: absolute;
			right: 0;
			width: 21.42%;
			height: 48.98%;

			&.-product-preview {
				top: 0;
				border-top-right-radius: 6px;
				background: $gray;

				.image {
					width: vwpx(150);
					height: vwpx(150);
					margin-bottom: vwpx(17);
					background: {
						position: left bottom;
						size: contain;
						repeat: no-repeat;
					}
					transition: all .4s ease;
				}

				&:hover {
					.image {
						transform: scale(1.1);
					}
				}
			}

			&.-contacts {
				overflow: hidden;
				bottom: 0;
				border-bottom-right-radius: 6px;
				background: $blue;
				color: #fff;

				&:before {
					content: "";
					position: absolute;
					bottom: vwpx(-115);
					right: vwpx(-115);
					width: vwpx(230);
					height: vwpx(230);
					border-radius: vwpx(230);
					background: $orange;
					transition: all .4s ease;
				}

				&:hover {
					&:before {
						transform: scale(1.2);
					}
				}
			}
		}
	}
}

.benefits {
	display: none;
	position: absolute;
	z-index: 2;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;

	@media #{$ds} {
		clip: rect(0, vwpx(1120), vwpx(240), vwpx(880));
		transform: translate(vwpx(-250), 0);
	}

	@media #{$mb} {
		top: 80px;
		bottom: 0;
		left: 0;
		height: auto;
		transform: translate(0, 100%);
		overflow: visible;
	}

	.background-wrapper {
		background: $orange;
		border-radius: 6px;
		min-height: 100%;
	}

	.title.u-big-title {
		@media #{$mb} {
			font-weight: 700;
			font-size: 14px;
			margin-top: 31px;
		}
	}

	.benefits-close {
		@media #{$mb} {
			top: -53px;
		}
	}

	.next-link {
		color: #fff;
		bottom: 24px;

		&:after {
			background: url(../images/icons/arrow-right-white.svg) center right / contain no-repeat;
		}
	}
}

.benefits-preview {
	position: absolute;
	z-index: 1;
	top: 0;
	left: vwpx(630);
	right: 0;
	bottom: vwpx(250);
	padding: 0 vwpx(38);
	box-sizing: border-box;
	overflow: hidden;;
	text-align: right;
	color: #ffffff;
	background: $orange;
	border-bottom-left-radius: 6px;
	transition: none;

	@media #{$ds} {
		transform: translateX(100%);
	}

	@media #{$mb} {
		border-bottom-right-radius: 6px;
		top: auto;
		bottom: 0;
		left: 0;
		right: 0;
		height: 80px;
		padding: 0 20px;
		text-align: left;
		line-height: 80px;
	}

	.title {
		position: relative;
		font-size: vwpx(24);
	  font-weight: bold;
	  margin-top: vwpx(74);
	}

	.desc {
		margin-top: vwpx(44);
		font-size: vwpx(16);
	  font-weight: bold;

	  @media #{$mb} {
	  	font-weight: 700;
	  	font-size: 20px;
	  	color: #FFFFFF;
	  	letter-spacing: -0.17px;
	  	margin: 0;

	  	span {
	  		display: inline-block;
	  		line-height: normal;
				border-bottom: 1px dashed;
	  	}
	  }
	}
}

.benefits-dash {
	position: absolute;
	z-index: 5;
	top: vwpx(85);
	left: vwpx(660);
	width: vwpx(35);
	height: vwpx(4);
	background: #ffffff;
	opacity: 0;
}

.u-big-title {
	position: relative;
	font-size: vwpx(36);
	font-weight: 500;
	line-height: 1.17;
	color: #ffffff;
	margin-top: vwpx(60);

	&:before {
		content: "";
		position: absolute;
		right: 100%;
		top: vwpx(20);
		margin-right: vwpx(20);
		display: inline-block;
		vertical-align: middle;
		width: vwpx(35);
		height: vwpx(4);
		background: #ffffff;

		@media #{$mb} {
			display: none;
		}
	}

	&.-black {
		color: #000000;
		
		&:before {
			background: #000;
		}
	}

	&.-no-dash {
		&:before {
			display: none;
		}
	}

	&.-light {
		font-weight: 300;

		&:before {
			height: vwpx(2);
			top: vwpx(21);
		}
	}
}

.main-slides {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	@media #{$mb} {
		position: relative;
		padding-bottom: 150px;
		transition: opacity .4s ease;
	}

	.slide {
		outline: none;

		.main-wrapper {
			@media #{$ds} {
				padding-right: vwpx(240);
			}
		}

		.title {
			@media #{$mb} {
				font-weight: 700;
				font-size: 24px;
				letter-spacing: -0.21px;
				line-height: 30px;
				margin-top: 40px;
			}
		}

		.icon {
			margin-top: vwpx(18);

			@media #{$mb} {
				margin-top: 20px;
			}

			.icon-wrapper {
				position: relative;
				display: inline-block;
				cursor: pointer;

				&.isFaded {
					transform: scale(0) !important;
				}

				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					margin-left: vwpx(-26);
					margin-top: vwpx(-26);
					border-radius: vwpx(52);
					width: vwpx(52);
					height: vwpx(52);
					background: {
						color: $blue;
						image: url(../images/icons/arrow-slider-right.svg);
						position: center;
						size: 50%;
						repeat: no-repeat;
					}
					transform: scale(0);
					transition: all .4s ease;
				}

				&:hover {
					&:before {
						transform: scale(1);
					}
				}
			}

			img {
				width: vwpx(60);

				@media #{$mb} {
					width: 50px;
				}
			}
		}

		.desc {
			margin-top: vwpx(64);
			font-size: vwpx(14);
			font-weight: 300;
			line-height: 1.5;
			color: #ffffff;


			@media #{$mb} {
				font-weight: 300;
				font-size: 14px;
				color: #FFFFFF;
				letter-spacing: 0;
				line-height: 24px;
				margin-top: 37px;
			}
		}

		@media #{$ds} {
			.title,
			.desc {
				transition: all .4s ease-out;
			}

			.icon {
				.icon-wrapper {
					transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
					transform: scale(0);
				}
			}

			&.slick-active {
				.title,
				.desc {
					opacity: 1;
				}

				.title {
					transition-delay: .2s;
				}

				.icon {
					.icon-wrapper {
						transform: scale(1);
						transition-delay: .4s;
					}
				}

				.desc {
					transition-delay: .6s;
				}
			}
		}
	}
}

.main-wrapper {
	padding: 0 vwpx(115);

	@media #{$mb} {
		padding: 0 20px;
	}
}

.benefits-list {
	margin-top: vwpx(100);
	padding: 0;
	list-style: none;

	@media #{$ds} {
		width: 105%;
	}

	@media #{$mb} {
		margin: 0;
		position: absolute;
		top: 84px;
		left: 0;
		right: 0;
		bottom: 0;

		.slick-dots {
			bottom: 28px;
		}
	}
}

@keyframes wallet {
  0%   { transform: rotate(-360deg) scale(0); }
  100% { transform: rotate(0) scale(1); }
}

@keyframes handshake {
  0%   { transform: translateY(0); }
  33%  { transform: translateY(-2%); }
 	66%  { transform: translateY(4%); }
  100% { transform: translateY(0); }
}

@keyframes rocket {
  0%   { transform: translate(0, 0); }
  16.6%  { transform: translate(-20%, 20%); }
 	33.3%  { transform: translate(-20%, 0); }
  50% { transform: translate(0, 20%); }
  66.6% { transform: translate(-20%, 20%); }
  83.3% { transform: translate(0, 20%); }
  100% { transform: translate(0, 0); }
}

@keyframes mark {
  0%   { transform: rotate(0); }
  30%  { transform: rotate(-20deg); }
 	60%  { transform: rotate(20deg); }
 	90%  { transform: rotate(-5deg); }
  100% { transform: rotate(0); }
}

.benefit-item {
	float: left;
	width: vwpx(200);
	margin-right: vwpx(30);
	color: #ffffff;
	box-sizing: border-box;

	@media #{$ds} {
		opacity: 0;
		transition: opacity .4s ease;
	}

	@media #{$mb} {
		width: auto;
		margin: 0;
		padding: 0 20px;
	}

	&.-longone {
		width: vwpx(210);
	}

	&:last-child {
		margin-right: 0;
	}

	> .icon {
		font-size: 0;

		.image {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			height: vwpx(60);
			width: vwpx(60);
			border-radius: vwpx(60);
			background-color: $blue;
			transition: all .4s ease;

			@media #{$mb} {
				width: 50px;
				height: 50px;
				border-radius: 50px;
			}
		}
	}

	> .title {
		margin-top: vwpx(27);
		height: vwpx(48);
		font-size: vwpx(18);
		font-weight: 500;
		line-height: 1.33;

	  @media #{$mb} {
	  	font-size: 24px;
	  	font-weight: 700;
		letter-spacing: -0.21px;
		line-height: 30px;
		margin-top: 15px;
		height: auto;
	  }
	}

	> .desc {
		margin-top: vwpx(13);
		font-size: vwpx(12);
		font-weight: 300;
		line-height: 1.333;

		@media #{$mb} {
			margin-top: 24px;
			font-size: 14px;
			letter-spacing: 0;
			line-height: 24px;
		}
	}

	.icon-image-container {
		position: relative;
		display: inline-block;

		@media #{$ds} {
			transform: scale(0);
		}
	}

	&:hover,
	&.slick-active {
		> .icon {
			.image {
				@media #{$ds} {
					transform: scale(1.2) !important;
					transition-delay: 0s !important;
				}

				.benefit-icon {
					&.-n1 {
						animation: wallet .5s 1;
					}

					&.-n2 {
						animation: handshake .5s 2;
					}

					&.-n3 {
						&:before {
							animation: rocket .2s 5;
						}
					}

					&.-n4 {
						transform-origin: 50% 45%;
						animation: mark .5s 1;
					}

					@media #{$mb} {
						animation-delay: .5s !important;
					}
				}
			}
		}
	}
}

.benefits-close {
	position: absolute;
	z-index: 1;
	display: inline-block;
	top: vwpx(63);
	right: vwpx(55);
	width: vwpx(32);
	height: vwpx(32);
	background: url(../images/cross.svg) center / 100%;
	transition: all .4s ease;

	@media #{$mb} {
		width: 32px;
		height: 32px;
	}

	&.-black {
		background: url(../images/cross-black.svg) center / 100%;
	}

	&:hover {
		@media #{$ds} {
			transform: rotate(180deg);
			backface-visibility: hidden;
		}
	}
}

.extra-content {
	position: absolute;
	bottom: vwpx(21);
	left: vwpx(30);
	right: vwpx(30);

	&.-with-dash {
		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: vwpx(155);
			display: inline-block;
			vertical-align: middle;
			width: vwpx(35);
			height: vwpx(4);
			background: #ffffff;
		}
	}

	> .title {
		font-weight: 700;
		font-size: vwpx(16);
		letter-spacing: 0;
	}

	> .desc {
		font-weight: 300;
		font-size: vwpx(14);
		letter-spacing: 0;
		line-height: vwpx(21);
		margin-top: vwpx(4);
	}
}

.catalog {
	display: none;
	position: absolute;
	z-index: 2;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	clip: rect(0, vwpx(1120), vwpx(240), vwpx(880));
	transform: translate(0, 0);

	.background-wrapper {
		background: $gray;
		border-radius: 6px;
	}
}

.catalog-list {
	list-style: none;
	margin: vwpx(25) vwpx(-25) 0;
	padding: 0;
	background: $gray;

	@media #{$ds} {
		position: relative;
		white-space: nowrap;
		width: 115.5%;
		box-sizing: border-box;

		&:after {
			content: "";
			position: absolute;
			z-index: 2;
			pointer-events: none;
			right: 0;
			top: 0;
			bottom: 0;
			width: vwpx(200);
			background: linear-gradient(to right, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 1) 100%);
		}

		.catalog-list__inner {
			&:after {
				content: "";
				display: inline-block;
				width: vwpx(200);
				height: 1px;
			}
		}

		.mCSB_scrollTools.mCSB_scrollTools_horizontal {
			z-index: 3;
			right: vwpx(40);
		}

		.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
			width: vwpx(250) !important;
		}

		.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
			height: 5px;
			margin: 5px auto;
			border-radius: 5px;
			background: $blue;
		}

		.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
			height: 5px;
			margin: 5px auto;
			background: #fff;
			border-radius: 5px;
		}
	}

	@media #{$mb} {
		margin: 0;
	}
}

.catalog-item {
	position: relative;
	width: vwpx(120);
	padding: vwpx(25) vwpx(25) 0;
	display: inline-block;
	vertical-align: top;
	font-size: vwpx(16);
	border-radius: 6px;
	transition: all .4s ease;
	white-space: normal;
	box-sizing: border-box;

	@media #{$ds} {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			opacity: 0;
			transition: all .4s ease;
		}

		&.-hover1 {
			&:before {
				background: {
					image: url(../images/animation/3.svg);
					position: left 20% center;
					size: 200%;
					repeat: no-repeat;
				}
			}
		}

		&.-hover2 {
			&:before {
				background: {
					image: url(../images/animation/4.svg);
					position: left 20% center;
					size: 200%;
					repeat: no-repeat;
				}
			}
		}

		&.-hover3 {
			&:before {
				background: {
					image: url(../images/animation/2.svg);
					position: left 20% center;
					size: 200%;
					repeat: no-repeat;
				}
			}
		}
	}

	@media #{$mb} {
		width: auto;
		padding: 26px 20px 85px;
		font-size: 16px;
		outline: none;
	}

	.section, .image, .name, .desc, .price, .link {
		position: relative;
		z-index: 2;
	}

	.section {
		font-weight: 300;
		font-size: vwpx(10);
		letter-spacing: 0;
		line-height: vwpx(14);
		min-height: vwpx(28);

		@media #{$ds} {
			width: 115%;
		}

		@media #{$mb} {
			font-weight: 300;
			min-height: 0;
			font-size: 14px;
			letter-spacing: 0;
			line-height: 21px;
			height: 42px;
		}
	}

	.image {
		width: vwpx(100);
		height: vwpx(100);
		background: {
			size: contain;
			repeat: no-repeat;
			position: left bottom;
		}
		margin-top: vwpx(7);

		@media #{$mb} {
			position: absolute;
			bottom: 90px;
			width: 33%;
			height: 144px;
			right: 5px;
			margin-top: 0;
			background: {
				position: right bottom;
			}
		}
	}

	.name {
		margin-top: vwpx(20);
		font-weight: 700;
		font-size: vwpx(16);
		letter-spacing: 0;

		@media #{$mb} {
			font-size: 20px;
			letter-spacing: 0;
			margin-top: 20px;
			height: 23px;
		}
	}

	.desc {
		font-weight: 300;
		font-size: vwpx(12);
		letter-spacing: 0;
		line-height: vwpx(21);

		@media #{$mb} {
			font-size: 14px;
			line-height: 21px;
			height: 21px;
		}
	}

	.price {
		margin-top: vwpx(16);
		font-weight: 300;
		font-size: vwpx(24);
		letter-spacing: vwpx(-0.67);

		@media #{$mb} {
			font-weight: 700;
			font-size: 36px;
			letter-spacing: -1px;
			margin-top: 24px;
		}
	}

	.link {
		display: block;
		margin: vwpx(11) vwpx(-25) 0;
		padding: 0 vwpx(25);
		opacity: 0;
		transition: all .4s ease;
		height: vwpx(50);
		line-height: vwpx(50);
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;

		span {
			transition: all .4s ease;
			color: #fff;
			vertical-align: middle;
		}

		&:hover {
			background: $orange;
		}
	}

	&:hover {
		@media #{$ds} {
			color: #fff;
			background: $blue;

			.link {
				opacity: 1;

				a {
					color: #fff;
				}
			}

			&:before {
				opacity: .2;
			}
		}
	}
}

.contacts {
	display: none;
	position: absolute;
	z-index: 2;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	clip: rect(vwpx(250), vwpx(1120), vwpx(490), vwpx(880));
	transform: translate(0, 0);

	.background-wrapper {
		background: $blue;
		border-radius: 6px;
	}

	.contacts-map {
		position: absolute;
		left: 0;
		right: 50%;
		top: 0;
		bottom: 0;
	}

	.contacts-content {
		position: absolute;
		right: 0;
		left: 50%;
		bottom: 0;
		top: 0;
		padding: 0 vwpx(70);
	}

	.contacts-link {
		position: absolute;
		bottom: vwpx(60);
		right: vwpx(54);
		bottom: vwpx(46);
	}
}

.contacts-block {
	float: left;
	margin-top: vwpx(44);
	color: #fff;

	&.-long {
		position: absolute;
		bottom: vwpx(46);
	}

	.block-title {
		font-weight: 500;
		font-size: vwpx(18);
		letter-spacing: vwpx(0.33);
		line-height: vwpx(24);

		a {
			display: inline-block;
			position: relative;
			margin-right: vwpx(23);
			text-decoration: none;
			color: #fff;
			cursor: pointer;
			font-weight: 300;

			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 2px;
				background: url(../images/dotted.png) left / auto 100%;
			}

			&.isActive {
				font-weight: 700;

				&:before {
					display: none;
				}
			}
		}
	}
	
	.block-content {
		font-weight: 300;
		font-size: vwpx(12);
		letter-spacing: 0;
		line-height: vwpx(18);
		margin-top: vwpx(36);

		.city-content {
			display: none;
		}
	}
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.feedback-block {
	display: none;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 6px;

	@media #{$mb} {
		position: fixed;
		z-index: 102;
		clip: auto;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		border-radius: 0;

		.header {
			padding: 0 20px;

			.logo {
				svg {
					.st0 {
						fill: #ffffff;
					}
				}
			}

			.u-link {
				color: #fff;
			}
		}

		.benefits-close {
			top: 112px;
		}

		.main-wrapper > .title {
			font-weight: 700;
			font-size: 24px;
			letter-spacing: -0.21px;
			line-height: 30px;
			margin-top: 24px;
		}

		.inner-content {
			padding-bottom: 24px;
		}

		.background-wrapper,
		.inner-content {
			position: relative;
		}
	}

	.background-wrapper {
		background: $blue;
	}
}

.u-input {
	font-weight: 100;
	font-size: vwpx(16);
	border-radius: 0;

	@media #{$ds} {
		margin-bottom: 0.5vw;
	}

	@media #{$mb} {
		font-size: 14px;
	}
}

.feedback-form {
	width: vwpx(700);
	margin-top: vwpx(55);
	color: #FFFFFF;

	@media #{$mb} {
		margin-top: 56px;
		width: auto;
	}

	.form-inputs {
		overflow: hidden;

		input {
			background: transparent;
			border: none;
			width: 30%;
			box-sizing: border-box;
			float: left;
			margin-left: 5%;
			height: vwpx(27);
			outline: none;

			@media #{$mb} {
				width: 100%;
				margin-left: 0;
				height: 34px;
				border-bottom: 1px solid rgba(255, 255, 255, .2);
				margin-top: 16px;
				transition: border .2s ease;

				&:focus {
					outline: none;
					border-bottom: 1px solid rgba(255, 255, 255, 1);
				}
			}

			&:first-child {
				margin-left: 0;
				
				@media #{$mb} {
					margin-top: 0;
				}
			}
		}
	}

	.form-textarea {
		margin-top: vwpx(30);

		textarea {
			width: 100%;
			height: vwpx(145);
			background: transparent;
			border: none;
			border-bottom: 1px solid rgba(255, 255, 255, .4);
			resize: none;
			outline: none;
			line-height: vwpx(24);

			@media #{$mb} {
				height: 200px;
				border-bottom: 1px solid rgba(255, 255, 255, .2);
				margin-top: 16px;
				transition: border .2s ease;
				line-height: 24px;

				&:focus {
					border-bottom: 1px solid rgba(255, 255, 255, 1);
					outline: none;
				}
			}
		}

		.textarea-left {
			font-weight: 300;
			font-size: vwpx(9);
			letter-spacing: 0;
			color: rgba(255, 255, 255, .4);
		}
	}

	.form-submit {
		margin-top: vwpx(30);

		@media #{$mb} {
			margin-top: 24px;
		}
	}
}

.animated-icons {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	pointer-events: none;

	@media #{$mb} {
		bottom: 80px;
	}

	svg {
		position: absolute;
		bottom: 0;
		right: 0;
		opacity: 0;
		transition: opacity .6s ease;

		&.isActive {
			opacity: 1;
		}

		&.-n1 {
			width: 80%;
		}

		&.-n2 {
			width: 90%;
			bottom: -2vw;
			right: -42%;

			@media #{$mb} {
				width: 110%;
				bottom: -6vw;
				right: -60%;
			}
		}

		&.-n3 {
			width: 34%;
			bottom: vwpx(35);
			right: vwpx(60);
		}

		&.-n4 {
			width: 55%;
			bottom: vwpx(-80);
		}
	}
}

.mobile-catalog {
	position: relative;
	margin-top: 20px;

	.slick-dots {
		bottom: 36px;

		li {
			background: rgba(36, 69, 156, .2);
			opacity: 1;

			&.slick-active {
				background: rgba(36, 69, 156, 1);
			}
		}
	}

	.next-link {
		color: #24459C;
		bottom: 33px;
	}
}

.next-link {
	position: absolute;
	z-index: 1;
	font-weight: 700;
	font-size: 14px;
	right: 20px;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: -0.12px;
	text-decoration: none;
	text-transform: lowercase;

	&:after {
		content: "";
		display: inline-block;
		vertical-align: middle;
		width: 7px;
		height: 10px;
		margin-left: 9px;
		background: url(../images/icons/arrow-right-blue.svg) center right / contain no-repeat;
	}
}

.mobile-order {
	display: block;
	height: 80px;
	line-height: 80px;
	padding: 0 20px;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	background: $blue;
	color: #fff;

	span {
		color: #fff;
		vertical-align: middle;
	}
}

.benefit-icon {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&.-n1 {
		background: url(../images/icons/about-1.svg) center / 48% no-repeat;
	}

	&.-n2 {
		background: url(../images/icons/about-2.svg) center / 57% no-repeat;
	}

	&.-n3 {
		background: url(../images/icons/about-3-1.svg) center / 45% no-repeat;

		&:before {
			content: "";
			position: absolute;
			top: 57%;
			right: 57%;
			width: 13.5%;
			height: 13.5%;
			background: url(../images/icons/about-3-2.svg) center / 100% no-repeat;
		}
	}

	&.-n4 {
		background: url(../images/icons/about-4.svg) center / 35% no-repeat;
	}
}

.u-gridworks-logo {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	top: vwpx(5);
	width: vwpx(40);
	height: vwpx(19);
	margin-left: vwpx(7);

	@media #{$mb} {
		width: 40px;
		height: 19px;
		margin-left: 7px;
		top: 5px;
	}

	svg {
		width: 102%;
		position: absolute;
		top: 0;
		left: 0;

		path {
			// transition: all .6s ease;
		}
	}
}

.feedback-sent {
	font-size: 16px;
	color: #FFFFFF;
	letter-spacing: 0;
	line-height: 30px;

	@media #{$mb} {
		font-size: 14px;
	}
}